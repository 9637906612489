<template>
  <b-modal
    id="modal-pembayaran"
    size="xl"
    title="List Pembayaran"
    header-bg-variant="success"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row style="row-gap: 10px;">
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_urut">No. Urut</label>
              <b-form-input
                id="no_urut"
                v-model="dataForm.no_urut"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_rm">No. RM</label>
              <b-form-input
                id="no_rm"
                v-model="dataForm.no_rm"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_bpjs">No. BPJS</label>
              <b-form-input
                id="no_bpjs"
                v-model="dataForm.no_bpjs"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="nama_lengkap">Nama Pasien</label>
              <b-form-input
                id="nama_lengkap"
                v-model="dataForm.nama_lengkap"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="12" lg="4">
              <label for="poliklinik">Poliklinik</label>
              <multiselect
                id="poliklinik"
                v-model="dataForm.poliklinik"
                :options="listPoliklinik"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_poli"
                track-by="ms_poli_id"
                placeholder="-- Poliklinik --"
                size="sm"
              ></multiselect>
            </b-col>
            <b-col cols="2" sm="12" md="6" lg="2">
              <label for="tanggal_awal">Tanggal awal</label>
              <date-picker
                style="width: 100%"
                id="tanggal_awal"
                format="DD-MM-YYYY HH:mm"
                show-hour
                show-minute
                timepicker 
                v-model="dataForm.tanggal_awal"
                @change="getData()"
              ></date-picker>
            </b-col>
            <b-col cols="2" sm="12" md="6" lg="2">
              <label for="tanggal_akhir">Tanggal akhir</label>
              <date-picker
                style="width: 100%"
                id="tanggal_akhir"
                format="DD-MM-YYYY HH:mm"
                show-hour
                show-minute
                timepicker 
                v-model="dataForm.tanggal_akhir"
                @change="getData()"
              ></date-picker>
            </b-col>
          </b-row>
          <b-row align-h="between" align-v="center" class="mt-3 mb-4">
            <b-col cols="auto">
              <h6 class="p-0 m-0"><strong>Total pendaftaran hari ini : {{totalRows}}</strong></h6>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex">
                <b-button
                  class="mr-2"
                  style="width:100%"
                  @click="refresh()"
                  variant="danger"
                  >Reset
                </b-button>
                <b-button
                  style="width:100%"
                  @click="getData()"
                  variant="success"
                  >Cari
                </b-button>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                responsive
                show-empty
                small
                bordered
                striped
                hover
                style="white-space:nowrap;"
                :busy="tableBusy"
              >
                <template #cell(actions)="item">
                  <div class="d-flex flex-nowrap">
                    <b-button
                      v-if="item.item.status_bayar == 1"
                      variant="danger"
                      v-c-tooltip.hover.click="'Belum Lunas'"
                      @click="$emit('pilihPembayaran', item.item), $bvModal.hide('modal-pembayaran')"
                      ><CIcon name="cil-info" /></b-button
                    >
                    <b-button
                      v-if="item.item.status_bayar == 2"
                      variant="info"
                      v-c-tooltip.hover.click="'Lunas'"
                      @click="$emit('pilihPembayaran', item.item), $bvModal.hide('modal-pembayaran')"
                      ><CIcon name="cil-info" /></b-button
                    >
                    <b-button
                      v-if="item.item.status_bayar == 3"
                      variant="success"
                      v-c-tooltip.hover.click="'Dibayar BPJS'"
                      @click="$emit('pilihPembayaran', item.item), $bvModal.hide('modal-pembayaran')"
                      ><CIcon name="cil-info" /></b-button
                    >
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-col md="5" offset-md="7">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-pembayaran')">
        Tutup
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from 'moment'
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  emits: ['pilihPembayaran'],
  props: ['listPoliklinik'],
  data() {
    return {
      items: [],
      is_data: {},
      dataForm: {
        no_urut: null,
        no_rm: null,
        no_bpjs: null,
        nama_lengkap: null,
        poliklinik: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
        {
          key: "no_urut",
          label: "No. Urut",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_rm",
          label: "No. RM",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "nama_poli",
          label: "Nama Poli",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_jenis_layanan",
          label: "Jenis Layanan",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_bpjs",
          label: "No. BPJS",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_daftar",
          label: "Tanggal",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_telp",
          label: "Telepon",
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
    }
  },
  watch: {
    'currentPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
    'perPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
    'dataForm.kategori_pencarian'(newVar, oldVar){
      if(newVar != oldVar){
        this.dataForm.kata_kunci = null
      }
    },
  },
  methods: {
    async openModal(){
      const vm = this
      // console.log('buka modal')
      if(!vm.dataForm.tanggal_awal){
        vm.dataForm.tanggal_awal = new Date()
        vm.dataForm.tanggal_awal.setHours(0)
        vm.dataForm.tanggal_awal.setMinutes(0)
        vm.dataForm.tanggal_awal.setSeconds(0)
      }
      if(!vm.dataForm.tanggal_akhir){
        vm.dataForm.tanggal_akhir = new Date()
        vm.dataForm.tanggal_akhir.setHours(23)
        vm.dataForm.tanggal_akhir.setMinutes(59)
        vm.dataForm.tanggal_akhir.setSeconds(59)
      }
      vm.getData()
      vm.getList()
    },
    async getList(){
      // const vm = this
    },
    async getData(){
      const vm = this
      vm.tableBusy = true
      const x = vm.dataForm
      //result
      // let result = await vm.$axios.post('/pendaftaran/list', {
      let result = await vm.$axios.post('/tagihan/list', {
        ...x, 
        ms_poli_id: x.poliklinik ? x.poliklinik.ms_poli_id : null,
        halaman: vm.currentPage - 1 + '', 
        jumlah : vm.perPage
      })
      if (result.data.status == 200) {
        vm.items = result.data.data.map((x, idx) => {
          return {
            ...x,
            no: idx + 1 + (this.currentPage - 1) * this.perPage,
            nama_tanggal_daftar: moment(x.tanggal_daftar).format('YYYY-MM-DD'),
            nama_jenis_layanan: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, false, 'kdTkp', 'text'),
          }
        })
      }
      // console.log('list tagihan', result)
      // console.log('ini tagihan', vm.items)
      vm.totalRows = parseInt(result.data.count)
      vm.tableBusy = false
    },
    refresh(){
      this.dataForm = {
        no_urut: null,
        no_rm: null,
        no_bpjs: null,
        nama_lengkap: null,
        poliklinik: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      }
      this.dataForm.tanggal_awal = new Date()
      this.dataForm.tanggal_awal.setHours(0)
      this.dataForm.tanggal_awal.setMinutes(0)
      this.dataForm.tanggal_awal.setSeconds(0)
      
      this.dataForm.tanggal_akhir = new Date()
      this.dataForm.tanggal_akhir.setHours(23)
      this.dataForm.tanggal_akhir.setMinutes(59)
      this.dataForm.tanggal_akhir.setSeconds(59)
      this.getData()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>