<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid class="pb-4">
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6 style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Pembayaran
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            
            <b-button variant="primary" @click="$bvModal.show('modal-pembayaran')" :disabled="busy"> 
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                    List Kunjungan
                </h6>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="p-0 pb-3 m-0 mx-4">
        <b-col cols="6" md="6" lg="6" xl="6" class="p-0 m-0">
          <b-row>
            <b-col cols="12" md="12" lg="12" xl="12" class="p-0 m-0">
              <h5 class="custom-title-color-black">Data Pasien</h5>
            </b-col>
            <b-col cols="12" md="12" lg="12" xl="12" class="p-0 m-0">
              <b-row class="p-0 m-0">
                <b-col cols="5" class="fw-600">No RM</b-col>
                <b-col cols="7">: {{ dataPembayaran.no_rm || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama pasien</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_lengkap || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Jenis kelamin</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_jenis_kelamin || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Umur</b-col>
                <b-col cols="7">: {{ dataPembayaran.umur || "-" }}</b-col>
                <b-col cols="5" class="fw-600">NIK</b-col>
                <b-col cols="7">: {{ dataPembayaran.nik || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No Telepon</b-col>
                <b-col cols="7">: {{ dataPembayaran.no_telp || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No BPJS</b-col>
                <b-col cols="7">: {{ dataPembayaran.no_bpjs || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Alamat sekarang</b-col>
                <b-col cols="7">: {{ dataPembayaran.alamat_sekarang || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Pekerjaan</b-col>
                <b-col cols="7">: {{ dataPembayaran.pekerjaan || "-" }}</b-col>
                <!-- <b-col cols="5" class="fw-600">Nama Ayah</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_ayah || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama Ibu</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_ibu || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Penjamin</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_penjamin || "-" }}</b-col> -->
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" md="6" lg="6" xl="6" class="p-0">
          <b-row>
            <b-col cols="12" md="12" lg="12" xl="12" class="p-0 m-0">
              <h5 class="custom-title-color-black">Data Kunjungan</h5>
            </b-col>
            <b-col cols="12" md="12" lg="12" xl="12" class="p-0 m-0">
              <b-row class="p-0 m-0">
                <b-col cols="5" class="fw-600">No Urut</b-col>
                <b-col cols="7">: {{ dataPembayaran.no_urut || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Jenis Layanan</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_jenis_layanan || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Tanggal Mulai</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_tanggal_mulai || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Tanggal Selesai</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_tanggal_selesai || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Poliklinik</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_poli || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Faskes Pertama</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_faskes_pertama || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Golongan</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_golongan || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Kelas</b-col>
                <b-col cols="7">: {{ dataPembayaran.nama_kelas || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Catatan Kunjungan</b-col>
                <b-col cols="7">: {{ dataPembayaran.catatan_kunjungan || "-" }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="dataFormPembayaranTagihan.tagihan_id">
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row v-if="dataFormPembayaranTagihan.tagihan_id" class="mx-3">
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <h5 class="custom-title-card">Data Tindakan & Pemeriksaan Laboratorium</h5>
            <div class="mb-2" style="display: flex; justify-content: flex-end">
              <b-button variant="warning" v-if="!lunas_pendaftaran" @click="$bvModal.show('modal-edit-pendaftaran')"> 
                <h6 class="m-0 p-0 text-light">
                      Edit Biaya Pendaftaran
                  </h6>
              </b-button>
            </div>
          <b-table-simple hover striped style="margin-bottom: 0;">
            <b-thead>
              <b-tr>
                <b-th>Item Tindakan & Pemeriksaan Laboratorium</b-th>
                <b-th>Jumlah</b-th>
                <b-th>Tarif (Rp)</b-th>
                <b-th>Total Tarif (Rp)</b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr v-for="(item, idx) in listTagihan" :key="idx">
                <b-td v-if="item.nama_tagihan" colspan="4" :class="item.status_tagihan_now ? 'text-success' : 'text-danger'">
                  <b-form-checkbox
                    v-if="!item.status_tagihan_now"
                    :id="'status_tagihan_'+idx"
                    v-model="item.status_tagihan"
                    :name="'status_tagihan_'+idx"
                    :value="true"
                    :unchecked-value="false"
                    @change="onTagihan(idx)"
                  >
                    <div style="margin-top: 2px;">
                      <b class="mt-1">{{ item.nama_tagihan }} - {{ item.nama_jumlah }}</b>
                    </div>
                  </b-form-checkbox>
                  <span v-else>
                    <b>{{ item.nama_tagihan }} - {{ item.nama_jumlah }}</b>
                  </span>
                </b-td>
                <b-td v-if="!item.nama_tagihan"><span class="ml-3">{{item.nama_sub_tagihan}}</span></b-td>
                <b-td v-if="!item.nama_tagihan">{{item.nama_jumlah}}</b-td>
                <b-td v-if="!item.nama_tagihan" class="text-right">{{item.nama_tarif}}</b-td>
                <b-td v-if="!item.nama_tagihan" class="text-right">{{item.nama_total_tarif}}</b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="3" class="text-right"><strong>Total Tarif</strong></b-td>
                <b-td class="text-right">{{$numberFormat(dataPembayaran.total_tarif, 'idr')}}</b-td>
              </b-tr>
              <!-- dataPembayaran.total_tarif {{dataPembayaran.total_tarif}} -->
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-if="dataFormPembayaranTagihan.tagihan_id" class="my-2">
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row v-if="dataFormPembayaranTagihan.tagihan_id" class="mx-3">
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <h5 class="custom-title-color-black">Riwayat Pembayaran</h5>
        </b-col>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0 m-0">
          <b-row class="p-0 m-0">
            <b-col cols="6">
              <label for="dibayar_oleh">Dibayar Oleh <span class="text-danger">*</span></label>
              <b-form-input
                id="dibayar_oleh"
                type="text"
                :state="checkIfValid('dataFormPembayaranTagihan', 'dibayar_oleh')"
                v-model="$v.dataFormPembayaranTagihan.dibayar_oleh.$model"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <label for="metode_pembayaran">Metode Pembayar <span class="text-danger">*</span></label>
              <Multiselect
                id="metode_pembayaran"
                :state="checkIfValid('dataFormPembayaranTagihan', 'metode_pembayaran')"
                v-model="dataFormPembayaranTagihan.metode_pembayaran"
                :options="$store.state.data_static.metode_pembayaran"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="-- Pilih Metode Pembayar --"
                size="sm"
              ></Multiselect>
            </b-col>
            <b-col cols="12">
              <label for="total_bayar">Total Bayar <span class="text-danger">*</span></label>
              <b-form-input
                id="total_bayar"
                type="text"
                :value="$numberFormat(dataFormPembayaranTagihan.total_bayar, 'idr')"
                disabled
              ></b-form-input>
            </b-col>
            <!-- <b-col cols="12">
              <label for="total_bayar">Total Bayar <span class="text-danger">*</span></label>
              <b-form-input
                id="total_bayar"
                type="number"
                :state="checkIfValid('dataFormPembayaranTagihan', 'total_bayar')"
                v-model="$v.dataFormPembayaranTagihan.total_bayar.$model"
                disabled
              ></b-form-input>
            </b-col> -->
            <!-- <b-col cols="6">
              <label for="sisa_tagihan">Sisa Tagihan <span class="text-danger">*</span></label>
              <b-form-input
                id="sisa_tagihan"
                type="number"
                :state="checkIfValid('dataFormPembayaranTagihan', 'sisa_tagihan')"
                v-model="$v.dataFormPembayaranTagihan.sisa_tagihan.$model"
                disabled
              ></b-form-input>
            </b-col> -->
            <b-col cols="12">
              <div style="text-align: right;">
                <b class="text-danger">* pembayaran yang sudah ditambahkan tidak bisa di ubah / di hapus</b>
              </div>
              <div class="mt-2 d-flex justify-content-end">
                <b-button
                  :disabled="busy || !isValidPembayaranTagihan"
                  @click="tambahPembayaranTagihan()"
                  variant="primary"
                >Tambah</b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <b-table
            :items="listRiwayarPembayaran"
            :fields="fieldsPembayaranTagihan"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(actions)="item">
              <div class="d-flex nowrap">
                <b-button
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  v-c-tooltip.hover.click="'Hapus Data'"
                  v-b-modal.modal-delete-pembayaran
                  @click="is_data = item.item"
                  ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                >
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- MODAL-->
      <b-modal
        id="modal-edit-pendaftaran"
        size="lg"
        title="Edit Pembayaran"
        header-bg-variant="success"
        header-text-variant="light"
        @show="openModal()"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-row style="row-gap: 10px;">
                <b-col cols="12" sm="12" md="12" lg="12">
                  <label>Biaya Pendaftaran </label>
                  <b-form-input
                    type="number"
                    v-model="dataForm.jumlah_tagihan_pendaftaran"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-pendaftaran')">
            Tutup
          </b-button>
          <b-button variant="primary" @click="simpan()">
            Simpan
          </b-button>
        </template>
      </b-modal>
    </b-container>
    
    <ModalPembayaran
      :listPoliklinik="listPoliklinik"
      @pilihPembayaran="pilihPembayaran"
    />
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getPembayaranTagihan()"
    />
  </div>
</template>
<script>
import ModalPembayaran from './modal_pembayaran.vue'
import ModalDelete from './modal_delete.vue'
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    ModalPembayaran,
    ModalDelete,
  },
  data(){
    return {
      busy: false,
      listPoliklinik: [],
      listTagihan: [],
      listRiwayarPembayaran: [],
      is_data: {},
      lunas_pendaftaran: false,
      dataForm: {
        jumlah_tagihan_pendaftaran: null,
      },
      dataFormPembayaranTagihan: {
        tagihan_id: null,
        dibayar_oleh: null,
        metode_pembayaran: null,
        total_bayar: 0,
        sisa_tagihan: 0,
        total_tagihan: 0,
      },
      dataPembayaran: {
        total_tarif: 0,
      },
      fieldsPembayaranTagihan: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal_bayar",
          label: "Tanggal Bayar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_total_bayar",
          label: "Total Bayar",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
        {
          key: "dibayar_oleh",
          label: "Dibayar Oleh",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "metode_pembayaran",
          label: "Metode Pembayaran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-2 text-center",
        // },
      ],
      data_pendaftaran: null,
    }
  },
  mixins: [validationMixin],
  validations: {
    dataFormPembayaranTagihan: {
      tagihan_id: { required },
      total_bayar: { 
        required, 
        numeric, 
        minValue: minValue(0), 
        // maxValue(value){
        //   return maxValue(this.dataFormPembayaranTagihan.total_tagihan)(value)
        // }
      },
      dibayar_oleh: { required },
      // sisa_tagihan: { required, numeric, minValue: minValue(0) },
      metode_pembayaran: { required },
    }
  },
  computed: {
    isValidPembayaranTagihan() {
      return !this.$v.dataFormPembayaranTagihan.$invalid;
    },
    isDirtyPembayaranTagihan() {
      return this.$v.dataFormPembayaranTagihan.$anyDirty;
    },
  },
  mounted() {
    const vm = this
    vm.getData()
    vm.getDataOther()
  },
  watch: {
    'dataFormPembayaranTagihan.total_bayar'(newVal, oldVal){
      if(newVal != oldVal){
        this.dataFormPembayaranTagihan.sisa_tagihan = this.dataFormPembayaranTagihan.total_tagihan - newVal
      }
    }
  },
  methods: {
    async openModal(){
      const vm = this
      console.log(vm.data_pendaftaran, 'buka modal')
      vm.dataForm.jumlah_tagihan_pendaftaran = vm.data_pendaftaran.jumlah_tagihan_pendaftaran
    },
    simpan(){
      let vm = this
      vm.$axios.post("/tagihan_pendaftaran/update", {
          id: vm.data_pendaftaran.tagihan_pendaftaran_id,
          jumlah_tagihan_pendaftaran: vm.dataForm.jumlah_tagihan_pendaftaran
      })
      .then((res) => {
          if(res.data.message == "sukses"){
              vm.detailTagihan()
              vm.$bvModal.hide('modal-edit-pendaftaran')
          }
          console.log(res);
      })
      .catch((err) => {
          console.log(err);
      })
    },
    checkIfValid(data, fieldName) {
      const field = this.$v[data][fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async onTagihan(i){
      const vm = this
      const tagihan = vm.listTagihan[i]
      // console.log(i)
      // console.log(tagihan)
      if(tagihan.status_tagihan){
        vm.dataFormPembayaranTagihan.total_bayar += tagihan.jumlah
      }else{
        vm.dataFormPembayaranTagihan.total_bayar -= tagihan.jumlah
      }
    },
    async pilihPembayaran(e){
      const vm = this
      try {
        console.log('pilih tagihan', e)
        vm.busy = true
        vm.dataPembayaran = e
        vm.dataPembayaran.total_tarif = 0
        vm.dataPembayaran.nama_jenis_kelamin = vm.$findKey(vm.$store.state.data_static.jenis_kelamin, e.jenis_kelamin, false)
        let expired = e.tanggal_lahir ? vm.$moment(e.tanggal_lahir) : vm.$moment()
        let now = vm.$moment()
        let hasil = vm.$moment.duration(now.diff(expired))
        vm.dataPembayaran.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`
        vm.dataFormPembayaranTagihan.tagihan_id = e.tagihan_id
        vm.getPembayaranTagihan()
        vm.detailTagihan()
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async detailTagihan(){
      const vm = this
      try {
        vm.busy = true
        const detail = await vm.$axios.get('/tagihan/details_by_id/' + vm.dataFormPembayaranTagihan.tagihan_id)
        console.log('detail tagihan', detail)
        vm.listTagihan = []
        vm.dataPembayaran.total_tarif = 0
        if(detail.data.status == 200 && detail.data.data.length >= 1){
          const item = detail.data.data[0]
          //pendaftaran
          vm.data_pendaftaran = item.data_tagihan_pendaftaran[0]
          if(vm.data_pendaftaran.status_tagihan_pendaftaran == true){
            vm.lunas_pendaftaran = true
          }else{
            vm.lunas_pendaftaran = false
          }
          if(item.data_tagihan_pendaftaran.length >= 1){
            vm.listTagihan.push({
              nama_tagihan: 'Pendaftaran',
              nama_jumlah: vm.$numberFormat(item.data_tagihan_pendaftaran[0].jumlah_tagihan_pendaftaran || 0, 'idr'),
              jumlah: item.data_tagihan_pendaftaran[0].jumlah_tagihan_pendaftaran,
              status_tagihan_now: item.data_tagihan_pendaftaran[0].status_tagihan_pendaftaran,
              status_tagihan: item.data_tagihan_pendaftaran[0].status_tagihan_pendaftaran,
              tagihan_pendaftaran_id: item.data_tagihan_pendaftaran[0].tagihan_pendaftaran_id,
            })
            for (let i = 0; i < item.data_tagihan_pendaftaran.length; i++) {
              const x = item.data_tagihan_pendaftaran[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_poli,
                nama_jumlah: 1,
                nama_tarif: vm.$numberFormat(x.jumlah_tagihan_pendaftaran || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.jumlah_tagihan_pendaftaran || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_pendaftaran[0].jumlah_tagihan_pendaftaran || 0)
          }
          //tindakan
          if(item.data_tagihan_tindakan.length >= 1){
            let jumlah = 0
            for (let i = 0; i < item.data_tagihan_tindakan.length; i++) {
              const x = item.data_tagihan_tindakan[i];
              jumlah += x.jumlah_tagihan_tindakan
            }
            // console.log('jumlah', jumlah)
            vm.listTagihan.push({
              nama_tagihan: 'Tindakan',
              jumlah: jumlah,
              // jumlah: item.data_tagihan_tindakan[0].jumlah_tagihan_tindakan,
              nama_jumlah: vm.$numberFormat(jumlah || 0, 'idr'),
              // nama_jumlah: vm.$numberFormat(item.data_tagihan_tindakan[0].jumlah_tagihan_tindakan || 0, 'idr'),
              status_tagihan_now: item.data_tagihan_tindakan[0].status_tagihan_tindakan,
              status_tagihan: item.data_tagihan_tindakan[0].status_tagihan_tindakan,
              tagihan_tindakan_id: item.data_tagihan_tindakan[0].tagihan_tindakan_id,
            })
            for (let i = 0; i < item.data_tagihan_tindakan.length; i++) {
              const x = item.data_tagihan_tindakan[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_tindakan,
                nama_jumlah: x.jumlah_tindakan,
                nama_tarif: vm.$numberFormat(x.jumlah_tagihan_tindakan || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.jumlah_tagihan_tindakan || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_tindakan[0].jumlah_tagihan_tindakan || 0)
          }
          //alkes
          if(item.data_tagihan_alkes.length >= 1){
            vm.listTagihan.push({
              nama_tagihan: 'Alkes',
              jumlah: item.data_tagihan_alkes[0].jumlah_tagihan_alkes,
              nama_jumlah: vm.$numberFormat(item.data_tagihan_alkes[0].jumlah_tagihan_alkes || 0, 'idr'),
              status_tagihan_now: item.data_tagihan_alkes[0].status_tagihan_alkes,
              status_tagihan: item.data_tagihan_alkes[0].status_tagihan_alkes,
              tagihan_alkes_id: item.data_tagihan_alkes[0].tagihan_alkes_id,
            })
            for (let i = 0; i < item.data_tagihan_alkes[0].data_sub_alkes.length; i++) {
              const x = item.data_tagihan_alkes[0].data_sub_alkes[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_barang,
                nama_jumlah: x.qty_alkes,
                nama_tarif: vm.$numberFormat(x.total_harga_item_alkes / x.qty_alkes || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.total_harga_item_alkes || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_alkes[0].jumlah_tagihan_alkes || 0)
          }
          //obat_poli
          if(item.data_tagihan_obat_poli.length >= 1){
            vm.listTagihan.push({
              nama_tagihan: 'Obat',
              jumlah: item.data_tagihan_obat_poli[0].jumlah_tagihan_obat_poli,
              nama_jumlah: vm.$numberFormat(item.data_tagihan_obat_poli[0].jumlah_tagihan_obat_poli || 0, 'idr'),
              status_tagihan_now: item.data_tagihan_obat_poli[0].status_tagihan_obat_poli,
              status_tagihan: item.data_tagihan_obat_poli[0].status_tagihan_obat_poli,
              tagihan_obat_poli_id: item.data_tagihan_obat_poli[0].tagihan_obat_poli_id,
            })
            for (let i = 0; i < item.data_tagihan_obat_poli[0].data_sub_obat.length; i++) {
              const x = item.data_tagihan_obat_poli[0].data_sub_obat[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_barang,
                nama_jumlah: x.qty_obat,
                nama_tarif: vm.$numberFormat(x.jumlah_tagihan_obat_poli / x.qty_obat || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.jumlah_tagihan_obat_poli || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_obat_poli[0].jumlah_tagihan_obat_poli || 0)
          }
          //lab
          if(item.data_tagihan_lab.length >= 1){
            vm.listTagihan.push({
              nama_tagihan: 'Laboratorium',
              jumlah: item.data_tagihan_lab[0].jumlah_tagihan_lab,
              nama_jumlah: vm.$numberFormat(item.data_tagihan_lab[0].jumlah_tagihan_lab || 0, 'idr'),
              status_tagihan_now: item.data_tagihan_lab[0].status_tagihan_lab,
              status_tagihan: item.data_tagihan_lab[0].status_tagihan_lab,
              tagihan_lab_id: item.data_tagihan_lab[0].tagihan_lab_id,
            })
            for (let i = 0; i < item.data_tagihan_lab[0].data_paket_lab.length; i++) {
              const x = item.data_tagihan_lab[0].data_paket_lab[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_paket_pemeriksaan,
                nama_jumlah: 1,
                nama_tarif: vm.$numberFormat(x.harga_paket_pemeriksaan || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.harga_paket_pemeriksaan || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_lab[0].jumlah_tagihan_lab || 0)
          }
          //resep
          if(item.data_tagihan_resep.length >= 1){
            vm.listTagihan.push({
              nama_tagihan: 'Resep',
              jumlah: item.data_tagihan_resep[0].jumlah_tagihan_resep,
              nama_jumlah: vm.$numberFormat(item.data_tagihan_resep[0].jumlah_tagihan_resep || 0, 'idr'),
              status_tagihan_now: item.data_tagihan_resep[0].status_tagihan_resep,
              status_tagihan: item.data_tagihan_resep[0].status_tagihan_resep,
              tagihan_resep_id: item.data_tagihan_resep[0].tagihan_resep_id,
            })
            for (let i = 0; i < item.data_tagihan_resep[0].data_racikan.length; i++) {
              const x = item.data_tagihan_resep[0].data_racikan[i];
              vm.listTagihan.push({
                nama_sub_tagihan: x.nama_barang,
                nama_jumlah: x.qty_barang,
                nama_tarif: vm.$numberFormat(x.harga_satuan_racikan || 0, 'idr'),
                nama_total_tarif: vm.$numberFormat(x.total_harga_racikan || 0, 'idr'),
              })
            }
            vm.dataPembayaran.total_tarif += Number.parseInt(item.data_tagihan_resep[0].jumlah_tagihan_resep || 0)
          }
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      // const vm = this
    },
    async getPembayaranTagihan(){
      const vm = this
      let listRiwayarPembayaran = await vm.$axios.post("/pembayaran_tagihan/list", {tagihan_id: vm.dataPembayaran.tagihan_id});
      // console.log('listRiwayarPembayaran', listRiwayarPembayaran)
      if(listRiwayarPembayaran.data.status == 200 && listRiwayarPembayaran.data.data.length >= 1){
        vm.listRiwayarPembayaran = listRiwayarPembayaran.data.data.map((x, idx) => { return {
          ...x,
          no: idx + 1,
          nama_tanggal_bayar: vm.$moment(x.tanggal_bayar).format('LL'),
          nama_total_bayar: vm.$numberFormat(x.total_bayar, 'idr'),
        }})
        vm.dataFormPembayaranTagihan.total_tagihan = vm.listRiwayarPembayaran[0].sisa_tagihan
        vm.dataFormPembayaranTagihan.sisa_tagihan = vm.listRiwayarPembayaran[0].sisa_tagihan
      }else{
        vm.listRiwayarPembayaran = []
        vm.dataFormPembayaranTagihan.total_tagihan = vm.dataPembayaran.total_tagihan 
        vm.dataFormPembayaranTagihan.sisa_tagihan = vm.dataPembayaran.total_tagihan 
      }
    },
    async getDataOther(){
      const vm = this
      //Poliklinik
      let listPoliklinik = await vm.$axios.post("/ms_poli/list");
      vm.listPoliklinik = listPoliklinik.data.status == 200 ? listPoliklinik.data.data : []
    },
    async tambahPembayaranTagihan(){
      const vm = this
      vm.busy = true
      try {
        // console.log('dataFormPembayaranTagihan 1', vm.dataFormPembayaranTagihan)
        for (let i = 0; i < vm.listTagihan.length; i++) {
          const x = vm.listTagihan[i];
          if(x.status_tagihan && !x.status_tagihan_now){
            vm.dataFormPembayaranTagihan = {...vm.dataFormPembayaranTagihan, ...x}
            // console.log(x)
          }
        }
        // console.log('dataFormPembayaranTagihan 2', vm.dataFormPembayaranTagihan)
        await this.$v.dataFormPembayaranTagihan.$touch();
        // console.log('dataFormPembayaranTagihan', vm.dataFormPembayaranTagihan)
        if (vm.isValidPembayaranTagihan && vm.isDirtyPembayaranTagihan) {
          let res = await vm.$axios.post('/pembayaran_tagihan/register', {
            ...vm.dataFormPembayaranTagihan,
            tanggal_bayar: vm.$moment()
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.triggerAlert({ variant: "success", msg: 'Berhasil Membuat Pembayaran', showing: true });
            vm.reset()
            vm.getPembayaranTagihan()
            vm.detailTagihan()
          }else{
            vm.triggerAlert({ variant: "danger", msg: 'Gagal Membuat Pembayaran', showing: true });
          }
        }else{
          vm.triggerAlert({ variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.triggerAlert({ variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataFormPembayaranTagihan = {
        tagihan_id: vm.dataPembayaran.tagihan_id,
        dibayar_oleh: null,
        metode_pembayaran: null,
        total_bayar: 0,
        sisa_tagihan: 0,
        total_tagihan: 0,
      }
      vm.$v.$reset()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>
<style scoped>
#page-pendaftaran .btn-primary {
  background-color: #9c4098;
}
.custom-title-color-black{
  font-weight: 700;
  font-size: 16px;
  color: #D52F65;
  }
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
  #page-laboratorium{
    font-family: 'Inter', sans-serif !important;
  }
  /* th{
    background-color: #D52F65;
    color:#fff;
    font-weight: 600;
  } */

  th,td{
    border:1px solid #F1F1F1 !important
  }

  .title-16{
    font-weight: 700;
    color:#D52F65;
    font-size: 16px;
  }

  .btn-primary{
    background-color: #9C4098;
  }

  .btn-outline-dark{
    /* color: #7C7C7C; */
    border-color: #7C7C7C;
    font-weight: 600;
  }

  .card-columns .card-body{
    padding: 10px;
  }

  .title-checkbox{
    font-weight: 600;
    color: #202020;
  }

  .font-checbox{
    font-weight: 400;
  }

  #collapse-1 .card{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
</style>